import React from 'react';

import styles from './NavigationButtons.module.scss';

const NavigationButtons = ({ next, prev, color, modal }) => {
  let classes = [styles.NavContainer];

  if (color) {
    let buttonColor = color === 'gray' ? styles.Gray : null;
    classes = [styles.NavContainer, buttonColor].join(' ');
  }

  if (modal) {
    classes = [...classes, styles.ModalButtons].join(' ');
  }

  return (
    <div className={classes}>
      <nav>
        <button onClick={prev}>‹</button>
        <button onClick={next}>›</button>
      </nav>
    </div>
  );
};

export default NavigationButtons;

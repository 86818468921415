import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './Portfolio.module.scss';

import BlockSlider from '../Sliders/BlockSlider/BlockSlider';
import { useEffect } from 'react';

const Portfolio = props => {
  const [width, setWidth] = useState(0);

  const { allFile, portfolioJson } = useStaticQuery(
    graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "portfolio" } }) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 150) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        portfolioJson {
          portfolio {
            name
            imageName
            url
          }
        }
      }
    `
  );

  let portfolio = null;

  portfolio = portfolioJson.portfolio.map(portfolioItem => {
    let image = null;
    image = allFile.edges.filter(
      item => item.node.name === portfolioItem.imageName
    );

    let id = portfolioItem.imageName || portfolioItem.name;

    if (typeof image[0] !== 'undefined') {
      return {
        id: id,
        name: portfolioItem.name,
        url: portfolioItem.url,
        image: image,
      };
    } else {
      return false;
    }
  });

  const portfolioFiltered = portfolio.filter(obj => obj);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    setWidth(window.innerWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  let blocksInSlider = 3;

  if (width < 450) {
    blocksInSlider = 1;
  } else if (width < 768) {
    blocksInSlider = 3;
  } else if (width < 960) {
    blocksInSlider = 4;
  } else {
    blocksInSlider = 6;
  }

  return (
    <section id="portfolio" className={styles.Portfolio}>
      <div className="container padding-1">
        <h2 className="section-title">Reference</h2>
        <BlockSlider slides={portfolioFiltered} blocks={blocksInSlider} />
      </div>
    </section>
  );
};

export default Portfolio;

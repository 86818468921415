import React from 'react';
// import { useStaticQuery, query } from 'gatsby';
// import Logo from '../../../Logo/Logo';

import styles from './TextSlide.module.scss';

const TextSlide = props => {
  return (
    <div className={styles.TextSlide}>
      <div className={styles.Text}>
        <h1>MONOLIT</h1>
        <h3>VISINSKI RADOVI</h3>
      </div>
    </div>
  );
};

export default TextSlide;

import React from 'react';
import { Link } from 'gatsby';
import { Capitalize } from '../../../../utilities/utilities';

import styles from './GoToButton.module.scss';

const GoToButton = ({ text, link, type }) => {
  text = text ? text : 'More...';
  link = link ? `/${link}` : '/';

  let classes = ['btn btn-primary inverse', styles.GoToButton];

  if (type) {
    classes.push(styles[Capitalize(type)]);
  }

  return (
    <Link to={link} className={classes.join(' ')}>
      {text}
    </Link>
  );
};

export default GoToButton;

import React from 'react';
import styles from './CardWithIcon.module.scss';

const CardWithIcon = ({ customClass, icon, name, description, type }) => {
  return (
    <div className={[customClass, styles.Card, styles[type]].join(' ')}>
      <img
        src={icon}
        alt={`${name} icon`}
        className={styles.Icon}
        width={48}
        height={48}
      />
      <h3 className={styles.Title}>{name}</h3>
      <div
        className={styles.Description}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
};

export default CardWithIcon;

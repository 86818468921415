import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './About.module.scss';
import Aux from '../Aux/Aux';
import Logo from '../Logo/Logo';
import GoToButton from '../UI/Buttons/GoToButton/GoToButton';

const About = ({ title, type }) => {
  const { aboutJson, bgImage } = useStaticQuery(
    graphql`
      query {
        aboutJson {
          text
          shortText
        }
        bgImage: allFile(
          filter: { relativePath: { eq: "backgrounds/vector-2.jpeg" } }
        ) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  );

  let image = null;
  let classes = [styles.About];
  let aboutType = null;
  let content = null;
  let aboutHTML = null;

  aboutType = styles.Full;
  content = aboutJson.text;

  aboutHTML = (
    <>
      <div
        className={styles.textBlock}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <GoToButton text="Kontaktirajte nas" link="kontakt" type="center" />
    </>
  );

  if (type === 'short') {
    aboutType = styles.Short;
    content = aboutJson.shortText;
    image = bgImage.edges[0].node.childImageSharp.fluid;

    aboutHTML = (
      <div className="grid">
        <div className="col-lg-4 col-md-12 center-horizontal">
          <Logo size="large" />
        </div>
        <div className="col-lg-8 col-md-12">
          <div
            className={styles.textBlock}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <GoToButton text="Više..." link="o-nama" />
        </div>
      </div>
    );
  }

  classes = [styles.About, aboutType].join(' ');

  return (
    <Aux tag="section" className={classes} fluidImage={image}>
      <div className={[styles.Container, 'container', 'padding-1'].join(' ')}>
        <h2 className="section-title">{title}</h2>
        {aboutHTML}
      </div>
    </Aux>
  );
};

export default About;

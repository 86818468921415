import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Aux from '../Aux/Aux';
import CardWithIcon from '../Cards/CardWithIcon/CardWithIcon';
import GoToButton from '../UI/Buttons/GoToButton/GoToButton';

import styles from './Services.module.scss';

const Services = ({ title, type }) => {
  const { icons, bgImage, servicesJson } = useStaticQuery(
    graphql`
      query {
        icons: allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "icons/services" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }

        bgImage: allFile(
          filter: { relativePath: { eq: "backgrounds/man-on-rope.png" } }
        ) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }

        servicesJson {
          featured {
            name
            description
            icon
          }
          all {
            name
            description
            icon
          }
        }
      }
    `
  );

  let services = null;
  let image = null;
  let button = (
    <GoToButton text="Kontaktirajte nas" link="kontakt" type="center" />
  );
  const titleHTML = <h2 className="section-title">{title}</h2>;

  services = servicesJson.all.map(service => {
    let icon = null;
    icon = icons.edges.filter(item => item.node.name === service.icon);

    return (
      <CardWithIcon
        key={service.name}
        name={service.name}
        description={service.description}
        icon={icon[0].node.publicURL}
        customClass="col-lg-6 col-md-12"
        type="left"
      />
    );
  });

  if (type === 'featured') {
    services = servicesJson.featured.map(service => {
      let icon = null;
      icon = icons.edges.filter(item => item.node.name === service.icon);

      return (
        <CardWithIcon
          key={service.name}
          name={service.name}
          description={service.description}
          icon={icon[0].node.publicURL}
          customClass="col-lg-4 col-md-6 col-sm-12 center-horizontal"
        />
      );
    });

    image = bgImage.edges[0].node.childImageSharp.fluid;
    button = <GoToButton text="Sve usluge" link="usluge" type="center" />;
  }

  return (
    <Aux tag="section" className={styles.Services} fluidImage={image}>
      <div className="container padding-1">
        {titleHTML}
        <div className="grid">
          {services}
          {button}
        </div>
      </div>
    </Aux>
  );
};

export default Services;

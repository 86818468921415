import React from 'react';

import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';
import About from '../components/About/About';
import Services from '../components/Services/Services';
import Portfolio from '../components/Portfolio/Portfolio';
import HeroSlider from '../components/Sliders/HeroSlider/HeroSlider';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Monolit - visinski radovi" banner="/monolit-thumb.png" />

      <HeroSlider />
      <Services title="Izdvojene usluge" type="featured" />
      <About title="O nama" type="short" />
      <Portfolio />
    </Layout>
  );
};

export default IndexPage;

import React, { useState, useEffect, useCallback } from 'react';
import Img from 'gatsby-image';
import NavigationButtons from '../../UI/Buttons/NavigationButtons/NavigationButtons';
import styles from './BlockSlider.module.scss';

const BlockSlider = ({ slides, blocks }) => {
  const [position, setPosition] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const length = slides.length;
  const blockWidth = 100 / blocks;
  const fullWidth = blockWidth * length;
  const move = 100 / length;
  const duration = 3000;

  const handleNext = useCallback(() => {
    if (!isPaused) {
      setPosition(position - move);
    }
  }, [move, position, isPaused]);

  const handlePrevious = () => {
    position >= 0 ? setPosition(0) : setPosition(position + move);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, duration);
    return () => clearInterval(interval);
  }, [position, handleNext]);

  if (Math.abs(position) >= 101 - move * blocks) {
    setPosition(0);
  }

  let slide = slides.map(item => {
    let image = null;

    image = (
      <a
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ width: blockWidth + '%' }}
        onMouseEnter={() => {
          setIsPaused(true);
          setPosition(position - 0.0001);
        }}
        onMouseLeave={() => {
          setIsPaused(false);
          setPosition(position + 0.0001);
        }}
        key={item.id}
      >
        <Img
          fluid={item.image[0].node.childImageSharp.fluid}
          alt={item.name}
          className={styles.Img}
        />
      </a>
    );

    return image;
  });

  return (
    <section className={styles.Slider}>
      <div className={styles.SlideContainer}>
        <div className={styles.Container}>
          <div
            className={styles.Slide}
            style={{
              width: fullWidth + '%',
              transform: `translate3d(${position}%, 0, 0)`,
            }}
          >
            {slide}
          </div>
        </div>
        <NavigationButtons
          next={() => handleNext()}
          prev={() => handlePrevious()}
          color="gray"
        />
      </div>
    </section>
  );
};
export default BlockSlider;

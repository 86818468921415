import React, { useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import NavigationButtons from '../../UI/Buttons/NavigationButtons/NavigationButtons';
import TextSlide from './TextSlide/TextSlide';
import styles from './HeroSlider.module.scss';

const HeroSlider = props => {
  const [index, setIndex] = useState(0);

  const { slides } = useStaticQuery(
    graphql`
      query {
        slides: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "slides" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(quality: 100, maxWidth: 2048) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  const imageSlidesData = slides.edges;

  let allSlides = [
    { node: { id: 'custom-slide-1', name: 'text' } },
    ...imageSlidesData,
  ];

  //Minus 1 for array offset from 0
  const length = allSlides.length - 1;

  const handleNext = useCallback(() => {
    index === length ? setIndex(0) : setIndex(index + 1);
  }, [index, length]);

  const handlePrevious = () => {
    index === 0 ? setIndex(length) : setIndex(index - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 7000);
    return () => clearInterval(interval);
  }, [index, handleNext]);

  const { node } = allSlides[index];

  let slide = null;

  if (node.name === 'text') {
    slide = <TextSlide />;
  } else {
    slide = (
      <Img
        fluid={node.childImageSharp.fluid}
        key={node.id}
        alt={node.name.replace(/-/g, ' ').substring(2)}
        style={{ width: '100%', height: 'inherit' }}
        durationFadeIn={1000}
      />
    );
  }

  return (
    <section className={styles.Slider}>
      <div className={styles.SlideContainer}>
        {slide}
        <NavigationButtons
          next={() => handleNext()}
          prev={() => handlePrevious()}
        />
      </div>
    </section>
  );
};

export default HeroSlider;
